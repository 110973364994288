// Libs
import React from 'react';
import classNames from 'classnames';

// Components
import Link from '@components/Link';
import Icon from '@components/Icon';

interface Props {
  path: string;
  label: string;
  arrowRight?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  showModal?: () => void;
  classes?: string;
}

const LinkPrevious: React.FC<Props> = ({
  path,
  label,
  arrowRight,
  onClick,
  showModal,
  classes,
}) => (
  <Link
    to={path}
    onClick={onClick}
    showModal={showModal}
    className={classNames(
      'inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading',
      classes
    )}
  >
    {!arrowRight && <Icon name="arrows/left" className="mr-2" size="12" />}
    {label}
    {arrowRight && <Icon name="arrows/right" className="ml-2" size="12" />}
  </Link>
);

export default LinkPrevious;
