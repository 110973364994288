import { gql } from '@apollo/client';

export const getGin = gql`
  query getGin($netForumMemberId: String!) {
    results: getGin(netForumMemberId: $netForumMemberId) {
      PAN_c
      RRN_c
      FiscalCode_c
    }
  }
`;
