import { TFunction } from 'i18next';
import * as yup from 'yup';

import {
  doesNotHaveNumbers,
  hasMaxLength,
  hasMinLength,
  hasValidPhoneNumberLength,
  isEmail,
  isNotRotaryEmail,
  isNumber,
  isRequired,
  isValidIndiaPanNumber,
  isValidItalyFiscalCode,
  isValidKoreanRrnNumber,
  matchLatinChars,
  matchLatinCharsAndIgnoreSign,
  matchValidStartCharacter,
  schemaField,
  SchemaFunction,
} from './schemaDefinitions';

const namesDefaultSchema = (
  t: TFunction,
  extraValidators: SchemaFunction[] = []
) =>
  schemaField(
    t,
    yup.string(),
    matchLatinChars,
    matchValidStartCharacter,
    hasMinLength(1),
    hasMaxLength(30),
    ...extraValidators
  );

export const firstNameSchema = (t: TFunction) =>
  namesDefaultSchema(t, [isRequired]);

export const lastNameSchema = firstNameSchema;

export const middleNameSchema = namesDefaultSchema;

export const localizedNameSchema = (t: TFunction, maxLength?: number) =>
  schemaField(t, yup.string(), hasMaxLength(maxLength || 200)).trim();

export const prefixSchema = (t: TFunction) =>
  schemaField(
    t,
    yup.string(),
    matchLatinChars,
    doesNotHaveNumbers,
    hasMaxLength(20)
  ).nullable();

export const numberSchema = (t: TFunction) =>
  schemaField(t, yup.string(), hasMaxLength(30), isNumber).nullable();

export const numberRequiredSchema = (t: TFunction) =>
  schemaField(t, yup.string(), hasMaxLength(30), isNumber, isRequired);

export const phoneNumberRequiredSchema = (t: TFunction) =>
  schemaField(t, yup.string(), isRequired, isNumber, hasValidPhoneNumberLength);

export const emailSchema = (t: TFunction) =>
  schemaField(t, yup.string(), hasMaxLength(64), matchLatinCharsAndIgnoreSign);

export const emailRequiredSchema = (t: TFunction) =>
  schemaField(t, yup.string(), hasMaxLength(64), isEmail, isRequired);
export const latinOnlyEmailSchema = (t: TFunction) =>
  schemaField(
    t,
    yup.string(),
    hasMaxLength(64),
    matchLatinCharsAndIgnoreSign,
    isEmail,
    isRequired
  );

export const nonLatinEmailSchema = (t: TFunction) =>
  schemaField(
    t,
    yup.string(),
    hasMaxLength(64),
    matchLatinCharsAndIgnoreSign,
    isEmail
  );

export const notRotaryEmailRequiredSchema = (t: TFunction) =>
  schemaField(
    t,
    yup.string(),
    hasMaxLength(64),
    isEmail,
    isNotRotaryEmail,
    isRequired
  );

export const requiredValidation = (t: TFunction) =>
  yup.string().required(t('form.field.error_required', 'Required'));

export const isValidIndiaPanNumberSchema = (t: TFunction) =>
  schemaField(t, yup.string(), isValidIndiaPanNumber);

export const isValidKoreanRrnNumberSchema = (t: TFunction) =>
  schemaField(t, yup.string(), isValidKoreanRrnNumber);

export const isValidItalyFiscalCodeSchema = (t: TFunction) =>
  schemaField(t, yup.string(), isValidItalyFiscalCode);
