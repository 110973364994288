import { gql } from '@apollo/client';

export const addRRN = gql`
  mutation AddRRN(
    $rrnId: String!
    $netForumMemberId: String!
    $collectionDate: String!
  ) {
    addRRN(
      rrnId: $rrnId
      netForumMemberId: $netForumMemberId
      collectionDate: $collectionDate
    ) {
      rrn
    }
  }
`;
