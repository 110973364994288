import { gql } from '@apollo/client';

export const addFiscalCode = gql`
  mutation AddFiscalCode(
    $fiscalCode: String!
    $netForumMemberId: String!
    $collectionDate: String!
  ) {
    addFiscalCode(
      fiscalCode: $fiscalCode
      netForumMemberId: $netForumMemberId
      collectionDate: $collectionDate
    ) {
      status
      errorMsg
    }
  }
`;
