import { useMutation } from '@apollo/client';

import { addFiscalCode } from '@repositories/government-id';

import {
  AddFiscalCodeMutation,
  AddFiscalCodeMutationVariables,
} from '@typings/operations';

export const useAddFiscalCode = () =>
  useMutation<AddFiscalCodeMutation, AddFiscalCodeMutationVariables>(
    addFiscalCode
  );
