import React, { useState } from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import Icon from '../Icon';
import Element, { ElementProps } from './Element';

import { useTranslation } from '@external/react-i18next';

export interface TextFieldProps extends ElementProps {
  /**
   * Placeholder text to be displayed when empty.
   */
  placeholder?: string;
  type?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  extraClasses?: string;
  passwordShowHide?: boolean;
  value?: string;
  isItalyFiscalCode?: boolean;
}

/**
 * Text field component. Allows displaying closed eye icon on the right and option
 * to see the entered text by clicking that eye icon.
 */
const TextField: React.FC<TextFieldProps> = props => {
  const {
    name,
    disabled,
    placeholder,
    type,
    inputRef,
    label,
    extraClasses,
    passwordShowHide,
    value,
    isItalyFiscalCode,
  } = props;
  const [field, meta] = useField<string>({
    name,
    defaultValue: '',
  });
  const { t } = useTranslation();
  const [state, setState] = useState({
    isClicked: false,
  });
  const [updatedType, setUpdatedType] = useState('text');
  let maskedInput = value;
  if (!state.isClicked) {
    if (isItalyFiscalCode) {
      maskedInput = value?.replace(/\w(?=\w{4})/g, '•') || '';
    } else {
      maskedInput = value?.replace(/\w(?=\w{3})/g, '•') || '';
    }
  }

  return (
    <Element {...props}>
      <input
        id={name}
        disabled={disabled}
        placeholder={placeholder}
        {...field}
        type={
          type ||
          (!state.isClicked && updatedType === 'password' ? 'password' : 'text')
        }
        onFocus={() => setUpdatedType('password')}
        autoComplete="off"
        className={
          !label
            ? ' '
            : classNames(
                'border border-gray-300 focus:border-dark-blue-400 rounded-sm',
                'w-full py-3 px-4 text-xs leading-loose focus-visible:outline-0',
                'focus:shadow-none',
                extraClasses,
                {
                  'bg-gray-100 cursor-not-allowed placeholder-gray-300 opacity-100 text-gray-600': disabled,
                  'placeholder-gray-400': !disabled,
                  'bg-red-100 border-red-600': !!meta.error && meta.touched,
                }
              )
        }
        ref={inputRef}
        value={!label ? maskedInput : value}
      />

      {passwordShowHide && (
        <button
          type="button"
          onClick={() => {
            const { isClicked } = state;
            setState({ isClicked: !isClicked });
          }}
          className={classNames(
            'absolute-vertical-center absolute cursor-pointer focus-visible:outline-0 focus:shadow-none',
            isItalyFiscalCode ? 'left-100' : 'z-0 right-0 mr-2 '
          )}
          data-element-type="clear-input"
        >
          <span className="sr-only">
            {t('forms.text-field.reset-button', 'Reset “{{label}}”', {
              label,
            })}
          </span>
          {state.isClicked ? (
            <Icon
              name="ui/eye"
              color="black-300 !outline-none focus-visible:outline-0 focus:shadow-none"
            />
          ) : (
            <Icon
              name="ui/eyeclosed"
              color="black-300 !outline-none focus-visible:outline-0 focus:shadow-none"
            />
          )}
        </button>
      )}
    </Element>
  );
};

export default TextField;
