import { QueryResult, useQuery } from '@apollo/client';

import { getGin } from '../queries';

import { GetGinQuery, GetGinQueryVariables } from '@typings/operations';

type GetGinResult = Pick<
  QueryResult<GetGinQuery>,
  'data' | 'error' | 'loading' | 'refetch'
>;

export const useGetGin = (netForumMemberId: string): GetGinResult =>
  useQuery<GetGinQuery, GetGinQueryVariables>(getGin, {
    variables: { netForumMemberId },
  });
